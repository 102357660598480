import { vpnActionType } from '../actions';

const initialState = {
    isLoading: false,

    // quante vpn machine ci sono dopo la chiamata della get al BE
    totalVpnMachine: '',

    // quante vpn account ci sono dopo la chiamata della get al BE
    totalVpnPass: '',

    // quante vpn peer ci sono dopo la chiamata della get al BE
    totalVpnPeer: '',

    // quante vpn box ci sono dopo la chiamata della get al BE
    totalVpnBox: '',

    // quante vpn box ci sono dopo la chiamata della get al BE
    idVpnPassForDetail: '',

    // machineId per visualizzare i peers corrispondenti
    machineIdForDetail: '',
};

export default function promo(state = initialState, action) {
    switch (action.type) {
        case vpnActionType.SET_NUMBER_VPN_MACHINE:
            return {
                ...state,
                totalVpnMachine: action.totalVpnMachine
            }
        case vpnActionType.SET_NUMBER_VPN_PEER:
            return {
                ...state,
                totalVpnPeer: action.totalVpnPeer
            }
        case vpnActionType.SET_ID_VPN_PASS_FOR_DETAIL:
            return {
                ...state,
                idVpnPassForDetail: action.idVpnPassForDetail
            }
        case vpnActionType.SET_KEY_VPN_PASS_FOR_DETAIL:
            return {
                ...state,
                keyVpnPassForDetail: action.keyVpnPassForDetail
            }
        case vpnActionType.SET_MACHINE_ID_S_FOR_DETAIL:
            return {
                ...state,
                machineIdForDetail: action.machineIdForDetail
            }
        case vpnActionType.SET_NUMBER_VPN_PASS:
            return {
                ...state,
                totalVpnPass: action.totalVpnPass
            }
        case vpnActionType.SET_NUMBER_VPN_BOX:
            return {
                ...state,
                totalVpnBox: action.totalVpnBox
            }
        case `${vpnActionType.ADD_VPN_MACHINE}_PENDING`:
        case `${vpnActionType.ADD_VPN_PASS}_PENDING`:
        case `${vpnActionType.DELETE_VPN_PASS}_PENDING`:
        case `${vpnActionType.EDIT_VPN_MACHINE}_PENDING`:
        case `${vpnActionType.EDIT_VPN_PASS}_PENDING`:
        case `${vpnActionType.EDIT_VPN_BOX}_PENDING`:
        case `${vpnActionType.DELETE_VPN_MACHINE}_PENDING`:
        case `${vpnActionType.DELETE_VPN_BOX}_PENDING`:
        case `${vpnActionType.SETUP_VPN_MACHINE}_PENDING`:
            return {
                ...state,
                isLoading: true
            }
        case `${vpnActionType.ADD_VPN_MACHINE}_FULFILLED`:
        case `${vpnActionType.ADD_VPN_PASS}_FULFILLED`:
        case `${vpnActionType.DELETE_VPN_PASS}_FULFILLED`:
        case `${vpnActionType.EDIT_VPN_MACHINE}_FULFILLED`:
        case `${vpnActionType.EDIT_VPN_PASS}_FULFILLED`:
        case `${vpnActionType.EDIT_VPN_BOX}_FULFILLED`:
        case `${vpnActionType.DELETE_VPN_MACHINE}_FULFILLED`:
        case `${vpnActionType.DELETE_VPN_BOX}_FULFILLED`:
        case `${vpnActionType.SETUP_VPN_MACHINE}_FULFILLED`:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
}