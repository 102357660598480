import React, { Component, Fragment } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Pagination, Select, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Loading from '../../common/loading';
import { countries } from '../../common/countries';
import { API_PAYMENT, PLAYER_TYPE, VPN_TYPE, COUPON_TYPE, VPNBOX_TYPE, API_PAYMENT_INVOICE } from '../../config';
import { controlRole } from '../../common/actions/controlRole';
import { isNull } from '../../utility';
import axiosInstance from '../../axiosInstance';

export default class formFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // quale campo ordinare
            fieldOrder: 'CreatedAt',

            // quanti utenti vuoi visualizzare
            numberPayment: '25',

            // numeri della pagina che si possono visualizzare (si popola in base alla risposta del server)
            numbersPage: [],

            // numerpo di pagina selezionato
            currentNumberPage: '1',

            // tipo di ordinamento
            typeOrder: 'desc',

            // inizializzazione dell'array filters payments
            paymentId: this.props.payment.idPaymentForDetail === '' ? '' : this.props.payment.idPaymentForDetail,
            paymentCustomerIp: '',
            paymentDeviceType: '',
            paymentDeviceId: '',
            // paymentProviderTransactionId: '',
            paymentCreatedAtStart: null,
            paymentCreatedAtEnd: null,
            paymentProvider: '',
            paymentStatus: '',
            paymentInvoiceNumber: '',
            paymentIssuedAtStart: null,
            paymentIssuedAtEnd: null,
            paymentRefundAtStart: null,
            paymentRefundAtEnd: null,
            paymentPromoId: '',
            transactionInPayment: '',
            parentPayment: '',
            subscription: '',

            // inizializzazione dell'array filters user
            userEmail: '',
            userName: '',
            userSurname: '',
            userCountry: '',

            // inizializzazione dell'array filters product
            productName: '',
            productDurationInDays: '',
            productPrice: '',

            // campo di ricerca delle nazioni entra UE
            checkedExtraUe: '',

            changeNumberPage: false,

            isLoading: false
        }
    }

    componentWillUnmount() {
        // quando esce dalla schermata dei pagamenti, resetta la variabile globale per id pagamento
        this.props.setIdPaymentForDetail('');
    }

    /**
     * Al click, effettua alcuni controlli di inserimento ricerca.
     * In caso di esito positivo, avvia la ricerca.
     * Altrimenti notifica all'utente cosa deve correggere
     */
    search = () => {
        if (this.state.fieldOrder !== '' && this.state.typeOrder === '') {
            this.props.warningNotify('Devi inserire il tipo di ordinamento!');
        } else {
            this.getPaymentList();
        }
    }

    /**
     * Chiama il BE e ritorna la lista dei pagamenti in base ai parametri di ricerca inseriti.
     * 
     * @param {Boolean} downloadAllInvoices se true, allora chiama l'API per scaricare le fatture utilizzando gli stessi filtri
     * @param {Boolean} creditNote se true, scarica le note credito (usato solo per i download)
     * @param {Boolean} XML se true, scarica i file xml (usato solo per i download)
     */
    getPaymentList = async (downloadAllInvoices, creditNote, XML) => {
        const { infoNotify, errorNotify, getPaymentList } = this.props;

        try {
            let params = {
                'elementsByPage': this.state.numberPayment,
                'page': this.state.changeNumberPage ? this.state.currentNumberPage : 1,
                'sortField': this.state.fieldOrder,
                'sortType': this.state.typeOrder,
                'filters[0][Id]': this.state.paymentId,
                'filters[1][CustomerIp]': this.state.paymentCustomerIp,
                'filters[2][ProductType]': this.state.paymentDeviceType,
                'filters[3][DeviceId]': this.state.paymentDeviceId,
                'filters[4][TransactionSearch]': this.state.transactionInPayment,
                'filters[5][CreatedAtStart]': this.state.paymentCreatedAtStart === null ? '' : this.state.paymentCreatedAtStart,
                'filters[6][CreatedAtEnd]': this.state.paymentCreatedAtEnd === null ? '' : this.state.paymentCreatedAtEnd,
                'filters[7][PaymentProvider]': this.state.paymentProvider,
                'filters[8][PaymentStatus]': this.state.paymentStatus,
                'filters[9][InvoiceNumber]': this.state.paymentInvoiceNumber,
                'filters[10][IssuedAtStart]': this.state.paymentIssuedAtStart === null ? '' : this.setHourStart(this.state.paymentIssuedAtStart.$d),
                'filters[11][IssuedAtEnd]': this.state.paymentIssuedAtEnd === null ? '' : this.setHourEnd(this.state.paymentIssuedAtEnd.$d),
                'filters[12][RefundAtStart]': this.state.paymentRefundAtStart === null ? '' : this.setHourStart(this.state.paymentRefundAtStart.$d),
                'filters[13][RefundAtEnd]': this.state.paymentRefundAtEnd === null ? '' : this.setHourEnd(this.state.paymentRefundAtEnd.$d),
                'filters[14][PromoId]': this.state.paymentPromoId,
                'filters[15][ParentPayment]': this.state.parentPayment,
                'filters[16][Subscription]': this.state.subscription === 'off' ? '' : this.state.subscription,
                'userFilters[0][Email]': this.state.userEmail,
                'userFilters[1][Name]': this.state.userName,
                'userFilters[2][Surname]': this.state.userSurname,
                'userFilters[3][Country]': this.state.userCountry,
                'userFilters[4][ExtraUe]': this.state.checkedExtraUe === 'off' ? '' : this.state.checkedExtraUe,
                'productFilters[0][Name]': this.state.productName,
                'productFilters[1][DurationInDays]': this.state.productDurationInDays,
                'productFilters[2][Price]': this.state.productPrice,
                'creditNote': creditNote ? true : false,
                'xml': XML ? true : false,
            };

            if (downloadAllInvoices) {
                this.setState({ isLoading: true });
                infoNotify('Attendi qualche secondo e le fatture verranno scaricate in un file zip! 🙂');
                let that = this;
                // se deve scaricare le fatture in uno zip
                await axiosInstance
                    .request({
                        url: API_PAYMENT_INVOICE,
                        method: 'GET',
                        params: params,
                        responseType: 'blob',
                    })
                    .then(({ data }) => {
                        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.setAttribute('download', creditNote ? 'Note-Credito.zip' : XML ? 'XML.zip' : 'Fatture.zip'); //any other extension
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        that.setState({ isLoading: false });
                    });


            } else {
                // se deve restituire solamente i pagamenti nella lista
                getPaymentList(params);

                if (!this.state.changeNumberPage) {
                    this.setState({ currentNumberPage: 1 });
                }

                if (this.state.changeNumberPage) {
                    this.setState({ changeNumberPage: false });
                }
            }

        } catch (result) {
            if (result && result.response && result.response.status) {
                switch (result.response.status) {
                    case 400: errorNotify('Inserisci un numero valido!'); break;
                    case 403: errorNotify('Error 403 - Non sei autorizzato!'); break;
                    default: errorNotify(`Error ${result.response.status} su ${API_PAYMENT} - GET`); break;
                }
            }
        }
    }

    /**
     * Se viene premuto il tasto enter (Invio) avvia una ricerca
     */
    clickEnter = (event) => {
        if (event.keyCode === 13) {
            this.search();
        }
    }

    setChange = (event, page) => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === '' || event.target.name === undefined) {
            this.setState({
                currentNumberPage: page,
                changeNumberPage: true
            });
            setTimeout(() => {
                this.search();
            }, 200);
        }

        if (event.target.name === 'numberPayment' ||
            event.target.name === 'typeOrder' ||
            event.target.name === 'productName' ||
            event.target.name === 'paymentProvider' ||
            event.target.name === 'paymentStatus' ||
            event.target.name === 'checkedExtraUe' ||
            event.target.name === 'subscription' ||
            event.target.name === 'paymentDeviceType' ||
            event.target.name === 'userCountry') {
            setTimeout(() => {
                this.search();
            }, 200);
        }
    }

    /**
     * In base alla risposta del server, 
     * setta il numero delle pagine disponibili per la paginazione
     */
    setNumbersPage = (numbersPage) => {
        let temp = [];
        for (let i = 1; i <= parseInt(numbersPage); i++) {
            temp.push(i);
        }
        this.setState({ numbersPage: temp });
    }

    setHourStart = (newHour) => {
        newHour.setHours(1);
        newHour.setMinutes(0);
        newHour.setSeconds(0);
        newHour.setMilliseconds(0);
        return newHour;
    }

    setHourEnd = (newHour) => {
        newHour.setHours(0);
        newHour.setMinutes(59);
        newHour.setSeconds(59);
        newHour.setMilliseconds(999);
        return newHour;
    }

    setDateCreatedChange = (newDate) => {
        this.setState({ paymentCreatedAtStart: newDate });
    }

    setDateEndChange = (newDate) => {
        this.setState({ paymentCreatedAtEnd: newDate });
    }

    setDateIssuedStartChange = (newDate) => {
        this.setState({ paymentIssuedAtStart: newDate });
    }

    setDateIssuedEndChange = (newDate) => {
        this.setState({ paymentIssuedAtEnd: newDate });
    }

    setDateRefundStartChange = (newDate) => {
        this.setState({ paymentRefundAtStart: newDate });
    }

    setDateRefundEndChange = (newDate) => {
        this.setState({ paymentRefundAtEnd: newDate });
    }

    setCheckedExtraUe = (event) => {
        this.setState({ checkedExtraUe: event.target.checked });
    }

    // Resetta tutti i filtri
    resetFilter = () => {
        this.setState({
            // quale campo ordinare
            fieldOrder: 'CreatedAt',

            numberPayment: '25',
            numbersPage: [],
            currentNumberPage: '1',

            // tipo di ordinamento
            typeOrder: 'desc',

            // inizializzazione dell'array filters payments
            paymentId: '',
            paymentCustomerIp: '',
            paymentDeviceType: '',
            paymentDeviceId: '',
            // paymentProviderTransactionId: '',
            paymentCreatedAtStart: null,
            paymentCreatedAtEnd: null,
            paymentProvider: '',
            paymentStatus: '',
            paymentInvoiceNumber: '',
            paymentIssuedAtStart: null,
            paymentIssuedAtEnd: null,
            paymentRefundAtStart: null,
            paymentRefundAtEnd: null,
            paymentPromoId: '',
            transactionInPayment: '',
            parentPayment: '',
            subscription: '',

            // inizializzazione dell'array filters user
            userEmail: '',
            userName: '',
            userSurname: '',
            userCountry: '',

            // inizializzazione dell'array filters licence
            productName: '',
            productDurationInDays: '',
            productPrice: '',

            // resetta il campo di ricerca delle nazioni entra UE
            checkedExtraUe: '',
        });

        setTimeout(() => {
            this.search();
        }, 300);
    }

    /**
     * Se decido di cambiare il numero dei pagamenti.
     * 
     * @param {*} number 
     */
    setNumberPaymentForSearch = (number) => {
        this.setState({ numberPayment: number });
        setTimeout(() => {
            this.search(true);
        }, 200);
    }

    render() {
        const { role, preferences, payment } = this.props;
        return (
            <Fragment>
                {(this.state.isLoading) && <Loading />}

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="paymentId"
                            variant='standard'
                            value={this.state.paymentId}
                            onChange={this.setChange}
                            onKeyDown={this.clickEnter}
                            label="ID Pagamento"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="paymentInvoiceNumber"
                            variant='standard'
                            value={this.state.paymentInvoiceNumber}
                            onChange={this.setChange}
                            onKeyDown={this.clickEnter}
                            label="Numero Fattura"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="transactionInPayment"
                            variant='standard'
                            value={this.state.transactionInPayment}
                            onChange={this.setChange}
                            label="ID Transazione Provider"
                            onKeyDown={this.clickEnter}
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="userEmail"
                            variant='standard'
                            onKeyDown={this.clickEnter}
                            value={this.state.userEmail}
                            onChange={this.setChange}
                            label="Email utente"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="userName"
                            variant='standard'
                            value={this.state.userName}
                            onChange={this.setChange}
                            onKeyDown={this.clickEnter}
                            label="Nome"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="userSurname"
                            variant='standard'
                            value={this.state.userSurname}
                            onChange={this.setChange}
                            onKeyDown={this.clickEnter}
                            label="Cognome"
                        />
                    </div>
                </div>

                <div className="row">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="col-12 itemForSearch">
                            <DatePicker
                                id="dateIssuedStart"
                                variant="inline"
                                format="DD/MM/YYYY"
                                label="Confermato Da"
                                value={this.state.paymentIssuedAtStart}
                                onChange={(newValue) => this.setDateIssuedStartChange(isNull(newValue) ? null : dayjs(newValue.$d))}
                            />
                        </div>

                        <div className="col-12 itemForSearch">
                            <DatePicker
                                id="dateIssuedEnd"
                                variant="inline"
                                label="Fino a"
                                value={this.state.paymentIssuedAtEnd}
                                onChange={(newValue) => this.setDateIssuedEndChange(isNull(newValue) ? null : dayjs(newValue.$d))}
                                format="DD/MM/YYYY"
                            />
                        </div>
                    </LocalizationProvider>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button
                            className={this.state.numberPayment === '10' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberPaymentForSearch('10')}
                        > 10 </button>
                        <button
                            className={this.state.numberPayment === '25' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberPaymentForSearch('25')}
                        > 25 </button>
                        <button
                            className={this.state.numberPayment === '50' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberPaymentForSearch('50')}
                        > 50 </button>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <Pagination
                            boundaryCount={2}
                            count={parseInt(payment.totalPages) || 0}
                            page={parseInt(this.state.currentNumberPage)}
                            onChange={this.setChange}
                            color="primary"
                            showFirstButton
                            showLastButton
                            size="small"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button className="btn btn-primary" style={{ 'margin': '10px' }} onClick={this.search}>
                            Cerca
                        </button>
                        <button className="btn btn-secondary" style={{ 'margin': '10px' }} onClick={this.resetFilter}>
                            Reset
                        </button>
                    </div>
                    <div className="col-12 text-center">
                        {
                            controlRole(role.payment, "api/Payment/GetInvoices", "GET") &&
                            <Fragment>
                                <button
                                    className="btn btn-warning"
                                    style={{ 'margin': '10px' }}
                                    onClick={() => { this.getPaymentList(true, false, false) }}
                                >
                                    Fatture
                                </button>

                                <button
                                    className="btn btn-warning"
                                    style={{ 'margin': '10px' }}
                                    onClick={() => { this.getPaymentList(true, false, true) }}
                                >
                                    XML
                                </button>
                            </Fragment>
                        }
                    </div>
                    <div className="col-12 text-center">
                        {
                            controlRole(role.payment, "api/Payment/GetInvoices", "GET") &&
                            <Fragment>
                                <button
                                    className="btn btn-warning"
                                    style={{ 'margin': '10px' }}
                                    onClick={() => { this.getPaymentList(true, true, false) }}
                                >
                                    Note Credito
                                </button>
                            </Fragment>
                        }
                    </div>
                </div>

                <Accordion className="myAccordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>Altri filtri</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Typography component="div">
                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Come vuoi ordinare?</InputLabel>

                                        <Select
                                            labelId="fieldOrderLabel"
                                            name="fieldOrder"
                                            variant='standard'
                                            value={this.state.fieldOrder}
                                            onChange={this.setChange}
                                        >
                                            <MenuItem value={'Id'}>ID Pagamento</MenuItem>
                                            <MenuItem value={'CreatedAt'}>Creazione Pagamento</MenuItem>
                                            <MenuItem value={'IssuedAt'}>Conferma Pagamento</MenuItem>
                                            <MenuItem value={'RefundAt'}>Rimborso Pagamento</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-12 itemForSearch">
                                    <FormControl disabled={this.state.fieldOrder === ''} className='customSelect'>
                                        <InputLabel>Tipo di Ordinamento</InputLabel>

                                        <Select
                                            labelId="typeOrderLabel"
                                            name="typeOrder"
                                            variant='standard'
                                            value={this.state.typeOrder}
                                            onChange={this.setChange}
                                        >
                                            <MenuItem value={'asc'}>Crescente [A-Z]</MenuItem>
                                            <MenuItem value={'desc'}>Decrescente [Z-A]</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Prodotto</InputLabel>
                                        <Select
                                            labelId="typeProductNameLabel"
                                            name="productName"
                                            variant='standard'
                                            value={this.state.productName}
                                            onChange={this.setChange}
                                        >
                                            {
                                                preferences.allProducts.map((el, key) => {
                                                    return (
                                                        <MenuItem key={key} value={el.name}>{el.name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="paymentPromoId"
                                        variant='standard'
                                        value={this.state.paymentPromoId}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="ID Promo"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="parentPayment"
                                        variant='standard'
                                        value={this.state.parentPayment}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="Parent Payment"
                                    />
                                </div>

                                <div className="col-12 itemForSearch">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Subscription</InputLabel>
                                        <Select
                                            labelId="subscription"
                                            name="subscription"
                                            variant='standard'
                                            value={this.state.subscription}
                                            onChange={this.setChange}
                                        >
                                            <MenuItem value='off'>Spento</MenuItem>
                                            <MenuItem value={false}>Sub - OFF</MenuItem>
                                            <MenuItem value={true}>Sub - ON</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            id="dateCreatedPayment"
                                            variant="inline"
                                            label="Creazione da"
                                            format="DD/MM/YYYY"
                                            value={this.state.paymentCreatedAtStart}
                                            onChange={(newValue) => this.setDateCreatedChange(isNull(newValue) ? null : dayjs(newValue.$d))}
                                        />
                                    </div>

                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            id="dateEndPayment"
                                            variant="inline"
                                            label="Fino a"
                                            value={this.state.paymentCreatedAtEnd}
                                            onChange={(newValue) => this.setDateEndChange(isNull(newValue) ? null : dayjs(newValue.$d))}
                                            format="DD/MM/YYYY"
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>

                            <div className="row">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            variant="inline"
                                            format="DD/MM/YYYY"
                                            id="dateRefundStart"
                                            label="Rimborsato Da"
                                            value={this.state.paymentRefundAtStart}
                                            onChange={(newValue) => this.setDateRefundStartChange(isNull(newValue) ? null : dayjs(newValue.$d))}
                                        />
                                    </div>

                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            id="dateRefundEnd"
                                            label="Fino a"
                                            value={this.state.paymentRefundAtEnd}
                                            onChange={(newValue) => this.setDateRefundEndChange(isNull(newValue) ? null : dayjs(newValue.$d))}
                                            variant="inline"
                                            format="DD/MM/YYYY"
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="paymentDeviceId"
                                        variant='standard'
                                        value={this.state.paymentDeviceId}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="ID Device"
                                        placeholder="Inserire tutto l'ID"
                                    />
                                </div>

                                <div className="col-12 itemForSearch">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Tipo device</InputLabel>

                                        <Select
                                            labelId="typeDeviceTypeLabel"
                                            name="paymentDeviceType"
                                            variant='standard'
                                            value={this.state.paymentDeviceType}
                                            onChange={this.setChange}
                                        >
                                            <MenuItem value={PLAYER_TYPE}>Player</MenuItem>
                                            <MenuItem value={VPN_TYPE}>VPN Pass</MenuItem>
                                            <MenuItem value={VPNBOX_TYPE}>VPN Box</MenuItem>
                                            <MenuItem value={COUPON_TYPE}>Coupon</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="paymentCustomerIp"
                                        variant='standard'
                                        value={this.state.paymentCustomerIp}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="Customer IP"
                                    />
                                </div>

                                <div className="col-12 itemForSearch">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Country</InputLabel>

                                        <Select
                                            labelId="typeUserCountryLabel"
                                            name="userCountry"
                                            variant='standard'
                                            value={this.state.userCountry}
                                            onChange={this.setChange}
                                        >
                                            {
                                                countries.map((item, k) => {
                                                    return (
                                                        <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Extra - Ue</InputLabel>

                                        <Select
                                            labelId="typeProductNameLabel"
                                            name="checkedExtraUe"
                                            variant='standard'
                                            value={this.state.checkedExtraUe}
                                            onChange={this.setChange}
                                        >
                                            <MenuItem value='off'>OFF</MenuItem>
                                            <MenuItem value={false}>Pre - Brexit</MenuItem>
                                            <MenuItem value={true}>Post - Brexit</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="productPrice"
                                        variant='standard'
                                        value={this.state.productPrice}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="Prezzo"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Provider</InputLabel>

                                        <Select
                                            labelId="typePaymentProviderLabel"
                                            name="paymentProvider"
                                            variant='standard'
                                            value={this.state.paymentProvider}
                                            onChange={this.setChange}
                                        >
                                            <MenuItem value={'paypal'}>PayPal</MenuItem>
                                            <MenuItem value={'stripe'}>Stripe</MenuItem>
                                            <MenuItem value={'bitpay'}>BitPay</MenuItem>
                                            <MenuItem value={'stark'}>Stark</MenuItem>
                                            <MenuItem value={'manual'}>Pagamento Manuale</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-12 itemForSearch">
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Stato</InputLabel>

                                        <Select
                                            labelId="typePaymentStatusLabel"
                                            name="paymentStatus"
                                            variant='standard'
                                            value={this.state.paymentStatus}
                                            onChange={this.setChange}
                                        >
                                            <MenuItem value={'0'}>Non confermato</MenuItem>
                                            <MenuItem value={'1'}>Approvato</MenuItem>
                                            <MenuItem value={'-1'}>Respinto</MenuItem>
                                            <MenuItem value={'2'}>In Attesa</MenuItem>
                                            <MenuItem value={'3'}>Rimborsato</MenuItem>
                                            <MenuItem value={'4'}>Sottoscrizione Attiva</MenuItem>
                                            <MenuItem value={'5'}>Sottoscrizione Disattivata</MenuItem>
                                            <MenuItem value={'6'}>Rimborso Parziale</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-12 itemForSearch">
                                </div>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

            </Fragment >
        )
    }
}