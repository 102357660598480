import React, { Component, Fragment } from 'react';
import { Select, FormControl, MenuItem, InputLabel, TextField } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { SUPER_ADMIN } from '../../config';
import { controlRole } from '../../common/actions/controlRole';
import ModalCreateLicence from '../../common/modal/modalCreateLicence';
import { isEmpty, isNull, isUndefined } from '../../utility';

export default class formFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // quale campo ordinare
            fieldOrder: '',

            // quanti player vuoi visualizzare
            numberPlayer: '25',

            // numerpo di pagina selezionato
            currentNumberPage: '1',

            // tipo di ordinamento
            typeOrder: '',

            // inizializzazione dell'array filters
            filterDeviceUser: '',
            filterDeviceRegistrationIp: '',
            filterDeviceId: this.props.player.idPlayerForDetail === '' ? '' : this.props.player.idPlayerForDetail,
            filterDeviceModel: '',
            filterDeviceVersion: '',
            filterDeviceDescription: '',
            filterDevicePlatform: '',
            filterDeviceDateCreated: null,
            filterDeviceDateEnd: null,

            // inizializzazione dell'array filters playlist
            filterPlaylistKey: '',
            filterPlaylistTitle: '',
            filterPlaylistSource: '',

            licenceType: '',

            // switch utenti registrati e non
            // checkedRegistered: true,
            checkedUnregistered: false,
            isRegistered: '',
            isUnregistered: '',
            disableFilterUser: false,
            changeNumberPage: false,

            // racchiude i filtri da mandare alla chiamata di creazione licenza
            filterLicence: {},

            hasClickCreateLicence: false,
            modal: false,
            moreItems: false,
            newTotalPages: 0
        }


        this.setChange = this.setChange.bind(this);
        this.setDateCreatedChange = this.setDateCreatedChange.bind(this);
        this.setDateEndChange = this.setDateEndChange.bind(this);
    }

    componentWillUnmount() {
        // quando esce dalla schermata dei player, resetta la variabile globale per id pagamento
        this.props.setIdPlayerForDetail('');
    }

    setChange = (event) => {

        this.setState({ [event.target.name]: event.target.value });

        if (event.target.name === 'numberPlayer' || event.target.name === 'typeOrder') {
            setTimeout(() => {
                this.getPlayerList(false);
            }, 200);
        }
    }

    setChangePagination = (event, page) => {
        if (isUndefined(page) && this.props.player.hasNext) {
            this.setState((prevState, props) => ({
                newTotalPages: prevState.currentNumberPage + 1,
                currentNumberPage: prevState.currentNumberPage + 1,
                changeNumberPage: true,
                moreItems: true
            }));

        } else if (event.target.name === '' || event.target.name === undefined) {
            this.setState({
                currentNumberPage: page,
                changeNumberPage: true
            });
        }

        setTimeout(() => {
            this.getPlayerList(false);
        }, 200);
    }

    /**
     * Se decido di cambiare il numero dei player.
     * 
     * @param {*} number 
     */
    setNumberPlayerForSearch = (number) => {
        this.setState({ numberPlayer: number });
        setTimeout(() => {
            this.getPlayerList(false);
        }, 200);
    }

    /**
     * Se viene premuto il tasto enter (Invio) avvia una ricerca
     */
    clickEnter = (event) => {
        if (event.keyCode === 13) {
            this.getPlayerList(false);
        }
    }

    /**
     * Chiama il BE e ritorna la lista degli utenti in base ai parametri di ricerca inseriti
     */
    getPlayerList = (downloadAllPlaylists) => {
        let params = {
            'elementsByPage': this.state.numberPlayer,
            'page': this.state.changeNumberPage ? this.state.currentNumberPage : 1,
            'sortField': this.state.fieldOrder,
            'sortType': this.state.typeOrder,
            'filters[0][User]': this.state.disableFilterUser ? '' : this.state.filterDeviceUser,
            'filters[1][Description]': this.state.filterDeviceDescription,
            'filters[2][CreatedAtStart]': this.state.filterDeviceDateCreated === null ? '' : this.setHourStart(this.state.filterDeviceDateCreated.$d),
            'filters[3][CreatedAtEnd]': this.state.filterDeviceDateEnd === null ? '' : this.setHourEnd(this.state.filterDeviceDateEnd.$d),
            'filters[4][DeviceId]': this.state.filterDeviceId,
            'filters[5][DeviceRegistrationIp]': this.state.filterDeviceRegistrationIp,
            'filters[6][DeviceModel]': this.state.filterDeviceModel,
            'filters[7][DeviceVersion]': this.state.filterDeviceVersion,
            'filters[8][DevicePlatform]': this.state.filterDevicePlatform,
            'playlistsFilters[0][key]': this.state.filterPlaylistKey,
            'playlistsFilters[1][Title]': this.state.filterPlaylistTitle,
            'playlistsFilters[2][Source]': this.state.filterPlaylistSource,
            'licenceFilters[0][LicenceFilter]': this.state.licenceType
        };

        setTimeout(() => {
            this.props.getPlayerList(downloadAllPlaylists, params);
        }, 500);

        if (!this.state.changeNumberPage) {
            this.setState({
                currentNumberPage: 1
            });
        }

        if (this.state.changeNumberPage) {
            this.setState({
                changeNumberPage: false,
            });
        }
    }

    setHourStart = (newHour) => {
        newHour.setHours(1);
        newHour.setMinutes(0);
        newHour.setSeconds(0);
        newHour.setMilliseconds(0);
        return newHour;
    }

    setHourEnd = (newHour) => {
        newHour.setHours(0);
        newHour.setMinutes(59);
        newHour.setSeconds(59);
        newHour.setMilliseconds(999);
        return newHour;
    }

    setDateCreatedChange = (newDate) => {
        this.setState({ filterDeviceDateCreated: newDate });
    }

    setDateEndChange = (newDate) => {
        this.setState({ filterDeviceDateEnd: newDate });
    }

    // Filtra i player che hanno o meno un utente registrato e associato a quel device
    hasRegistered = () => {
        if (this.state.checkedUnregistered) {
            this.setState({
                disableFilterUser: true,
                filterDeviceUser: null
            });
        } else {
            this.setState({
                disableFilterUser: false,
                filterDeviceUser: ''
            });
        }

        this.getPlayerList(false);
    }

    setCheckedRegistered = (event) => {
        this.setState({ checkedRegistered: event.target.checked });
        setTimeout(() => {
            this.hasRegistered();
        }, 100);
    }

    setCheckedUnregistered = (event) => {
        this.setState({ checkedUnregistered: event.target.checked });
        setTimeout(() => {
            this.hasRegistered();
        }, 100);
    }

    // Resetta tutti i filtri
    resetFilter = () => {
        this.setState({
            numberPlayer: '25',
            currentNumberPage: '1',

            fieldOrder: '',
            typeOrder: '',

            filterDeviceDateCreated: null,
            filterDeviceDateEnd: null,
            filterDeviceUser: '',
            filterDeviceRegistrationIp: '',
            filterDeviceId: '',
            filterDeviceModel: '',
            filterDeviceVersion: '',
            filterDeviceDescription: '',
            filterDevicePlatform: '',

            filterPlaylistKey: '',
            filterPlaylistTitle: '',
            filterPlaylistSource: '',

            filterLicence: {},
            licenceType: ''
        });

        setTimeout(() => {
            this.getPlayerList(false);
        }, 300);
    }

    hasClickCreateLicence = () => {
        const { player, errorNotify } = this.props;

        if (isEmpty(player.paramsGetPlayerListTemp)) {
            errorNotify('Effettua una Ricerca prima di Creare una licenza');

        } else if (this.state.licenceType !== 1) {
            errorNotify('Abilita il filtro "Tipo Licenze" su "Licenze Non Valide" prima di effettuare la ricerca');

        } else if (player.totalPlayers > 1000) {
            errorNotify('Non puoi creare delle licenze su più di 1000 elementi. Filtra in modo tale da ridurne il numero');

        } else {
            this.setState({
                hasClickCreateLicence: true,
                filterLicence: {
                    'elementsByPage': 25,
                    'page': 1,
                    'filters[0][User]': this.state.disableFilterUser ? '' : this.state.filterDeviceUser,
                    'filters[1][Description]': this.state.filterDeviceDescription,
                    'filters[2][CreatedAtStart]': this.state.filterDeviceDateCreated === null ? '' : this.setHourStart(this.state.filterDeviceDateCreated.$d),
                    'filters[3][CreatedAtEnd]': this.state.filterDeviceDateEnd === null ? '' : this.setHourEnd(this.state.filterDeviceDateEnd.$d),
                    'filters[4][DeviceId]': this.state.filterDeviceId,
                    'filters[5][DeviceRegistrationIp]': this.state.filterDeviceRegistrationIp,
                    'filters[6][DeviceModel]': this.state.filterDeviceModel,
                    'filters[7][DeviceVersion]': this.state.filterDeviceVersion,
                    'filters[8][DevicePlatform]': this.state.filterDevicePlatform,
                    'playlistsFilters[0][key]': this.state.filterPlaylistKey,
                    'playlistsFilters[1][Title]': this.state.filterPlaylistTitle,
                    'playlistsFilters[2][Source]': this.state.filterPlaylistSource,
                    'licenceFilters[0][LicenceFilter]': this.state.licenceType
                }
            });

            setTimeout(() => {
                this.setState(prevState => ({ modal: !prevState.modal }));
            }, 200);
        }
    }

    toggleModal = () => {
        this.setState(prevState => ({ modal: !prevState.modal }));
        this.setState({ hasClickCreateLicence: false });
    }

    render() {
        const { role, user } = this.props;
        return (
            <Fragment>
                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="filterDeviceId"
                            variant='standard'
                            value={this.state.filterDeviceId}
                            onChange={this.setChange}
                            onKeyDown={this.clickEnter}
                            label="ID Device"
                        />
                    </div>
                </div>

                <div className="row">
                    <div className='col-12 itemForSearch'>
                        <FormControl className='customSelect'>
                            <InputLabel>Tipo di Licenze</InputLabel>
                            <Select
                                labelId="keyLicence"
                                name="licenceType"
                                variant='standard'
                                value={this.state.licenceType}
                                onChange={this.setChange}
                            >
                                <MenuItem key="0" value="">Nessun Filtro</MenuItem>
                                <MenuItem key="1" value={0}>Licenze Valide</MenuItem>
                                <MenuItem key="2" value={1}>Licenze Non Valide</MenuItem>
                                <MenuItem key="3" value={2}>Periodo di Prova</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button
                            className={this.state.numberPlayer === '10' ? "btnAround selected" : "btnAround"}
                            onClick={() => { this.setNumberPlayerForSearch('10'); this.setState({ moreItems: false, newTotalPages: 0, currentNumberPage: 1 }) }}
                        > 10 </button>
                        <button
                            className={this.state.numberPlayer === '25' ? "btnAround selected" : "btnAround"}
                            onClick={() => { this.setNumberPlayerForSearch('25'); this.setState({ moreItems: false, newTotalPages: 0, currentNumberPage: 1 }) }}
                        > 25 </button>
                        <button
                            className={this.state.numberPlayer === '50' ? "btnAround selected" : "btnAround"}
                            onClick={() => { this.setNumberPlayerForSearch('50'); this.setState({ moreItems: false, newTotalPages: 0, currentNumberPage: 1 }) }}
                        > 50 </button>
                    </div>
                </div>

                <div className="row itemForSearch justify-content-center">
                    <div className="col-12 text-center d-flex">
                        <Pagination
                            boundaryCount={2}
                            count={this.state.moreItems ? parseInt(this.state.newTotalPages) : parseInt(this.props.player.totalPages) || 0}
                            page={parseInt(this.state.currentNumberPage)}
                            onChange={this.setChangePagination}
                            color="primary"
                            showFirstButton
                            showLastButton
                            size="small"
                        />
                    </div>

                    <button
                        className="btnMoreItems"
                        onClick={this.setChangePagination}
                        disabled={isEmpty(this.props.player.paramsGetPlayerListTemp) || (parseInt(this.state.currentNumberPage) < parseInt(this.props.player.totalPages)) || (parseInt(this.state.currentNumberPage) < parseInt(this.state.newTotalPages)) || !this.props.player.hasNext}
                    >Pag. Extra <i className='fas fa-chevron-circle-right'></i></button>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button className="btn btn-primary" style={{ 'margin': '10px' }} onClick={() => this.getPlayerList(false)}>
                            Cerca
                        </button>

                        <button className="btn btn-secondary" style={{ 'margin': '10px' }} onClick={this.resetFilter}>
                            Reset
                        </button>

                        {
                            controlRole(role.player, "api/Player/CreateLicencesByFilters", "POST") &&
                            <button
                                className="btn btn-warning"
                                style={{ 'margin': '10px' }}
                                onClick={() => { this.hasClickCreateLicence() }}
                            >
                                Crea Licenze
                            </button>
                        }

                        {
                            user.user.role === SUPER_ADMIN &&
                            <button
                                className="btn btn-warning"
                                style={{ 'margin': '10px' }}
                                onClick={() => { this.getPlayerList(true) }}
                            >
                                Playlists
                            </button>
                        }
                    </div>
                </div>

                <Accordion className="myAccordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>Altri filtri</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div">
                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Come vuoi ordinare?</InputLabel>
                                        <Select
                                            labelId="fieldOrderPlayersLabel"
                                            name="fieldOrder"
                                            variant='standard'
                                            value={this.state.fieldOrder}
                                            onChange={this.setChange}
                                        >
                                            <MenuItem value={'User'}>Utente</MenuItem>
                                            <MenuItem value={'Id'}>ID Device</MenuItem>
                                            <MenuItem value={'CreatedAt'}>Data</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-12 itemForSearch">
                                    <FormControl disabled={this.state.fieldOrder === ''} className='customSelect'>
                                        <InputLabel>Tipo di Ordinamento</InputLabel>
                                        <Select
                                            labelId="typeOrderLabel"
                                            name="typeOrder"
                                            variant='standard'
                                            value={this.state.typeOrder}
                                            onChange={this.setChange}
                                        >
                                            <MenuItem value={'asc'}>Crescente [A-Z]</MenuItem>
                                            <MenuItem value={'desc'}>Decrescente [Z-A]</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            id="dateCreated"
                                            variant="inline"
                                            label="Creazione da"
                                            value={this.state.filterDeviceDateCreated}
                                            onChange={(newValue) => this.setDateCreatedChange(isNull(newValue) ? null : dayjs(newValue.$d))}
                                            format="DD/MM/YYYY"
                                        />
                                    </div>

                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            id="dateEnd"
                                            variant="inline"
                                            label="Fino a"
                                            value={this.state.filterDeviceDateEnd}
                                            onChange={(newValue) => this.setDateEndChange(isNull(newValue) ? null : dayjs(newValue.$d))}
                                            format="DD/MM/YYYY"
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="filterDeviceRegistrationIp"
                                        variant='standard'
                                        value={this.state.filterDeviceRegistrationIp}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="indirizzo IP"
                                    />
                                </div>

                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="filterDevicePlatform"
                                        variant='standard'
                                        value={this.state.filterDevicePlatform}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="piattaforma"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="filterDeviceModel"
                                        variant='standard'
                                        value={this.state.filterDeviceModel}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="modello"
                                    />
                                </div>

                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="filterPlaylistTitle"
                                        variant='standard'
                                        value={this.state.filterPlaylistTitle}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="Titolo della playlist"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="filterPlaylistSource"
                                        variant='standard'
                                        value={this.state.filterPlaylistSource}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="URL della playlist"
                                    />
                                </div>

                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="filterDeviceUser"
                                        variant='standard'
                                        value={this.state.filterDeviceUser}
                                        onChange={this.setChange}
                                        label="Email utente"
                                        onKeyDown={this.clickEnter}
                                        InputProps={{ readOnly: this.state.disableFilterUser }}
                                    />
                                </div>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                {
                    this.state.hasClickCreateLicence &&
                    <ModalCreateLicence
                        titleModal='Crea Licenza'
                        stateModal={this.state.modal}
                        toggle={this.toggleModal}

                        filterLicence={this.state.filterLicence}
                        sendData={this.props.createLicences}
                        user={user}

                        errorNotify={this.props.errorNotify}
                    />
                }
            </Fragment>
        )
    }
}