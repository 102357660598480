export const userActionType = {
    GET_USER: 'GET_USER',
    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_TFA: 'RESET_TFA',
    DELETE_USER: 'DELETE_USER',
    SEND_MAIL: 'SEND_MAIL',
    SEND_NEW_USER: 'SEND_NEW_USER',
    UPDATE_ADDRESS: 'UPDATE_ADDRESS',
    UPDATE_USER: 'UPDATE_USER',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    GET_LIST_EMAILS: 'GET_LIST_EMAILS'
};

export const playerActionType = {
    SEND_NEW_PLAYLIST: 'SEND_NEW_PLAYLIST',
    SEND_MODIFY_PLAYLIST: 'SEND_MODIFY_PLAYLIST',
    REMOVE_PLAYLIST: 'REMOVE_PLAYLIST',
    SEND_NEW_PLAYER: 'SEND_NEW_PLAYER',
    SEND_EDIT_PLAYER: 'SEND_EDIT_PLAYER',
    REMOVE_PLAYER: 'REMOVE_PLAYER',
    SET_NUMBER_PLAYERS: 'SET_NUMBER_PLAYERS',
    REFRESH_PLAYER: 'REFRESH_PLAYER',
    ADD_PASS_TO_PLAYER: 'ADD_PASS_TO_PLAYER',
    SEND_MESSAGE_PLAYER: 'SEND_MESSAGE_PLAYER',
    SET_ID_PLAYER_FOR_DETAIL: 'SET_ID_PLAYER_FOR_DETAIL',
    GET_PLAYER: 'GET_PLAYER',
    GET_PLAYLIST: 'GET_PLAYLIST',
    GET_PLAYER_LICENCE: 'GET_PLAYER_LICENCE',
    CHANGE_DNS_TO_PLAYLIST: 'CHANGE_DNS_TO_PLAYLIST',
    CREATE_NEW_LICENCE: 'CREATE_NEW_LICENCE'
}

export const notifyActionType = {
    NOTIFY_SUCCESS: 'NOTIFY_SUCCESS',
    NOTIFY_ERROR: 'NOTIFY_ERROR',
    NOTIFY_WARNING: 'NOTIFY_WARNING',
    NOTIFY_INFO: 'NOTIFY_INFO'
}

export const groupActionType = {
    GET_GROUP: 'GET_GROUP',
    ADD_GROUP: 'ADD_GROUP',
    UPDATE_GROUP: 'UPDATE_GROUP',
    DELETE_GROUP: 'DELETE_GROUP',
    ASSIGN_GROUP: 'ASSIGN_GROUP'
}

export const paymentActionType = {
    GET_PAYMENT: 'GET_PAYMENT',
    DELETE_PAYMENT: 'DELETE_PAYMENT',
    CANCEL_SUB: 'CANCEL_SUB',
    REFUND_PAYMENT: 'REFUND_PAYMENT',
    UPDATE_DEVICE: 'UPDATE_DEVICE',
    SET_ID_PAYMENT_FOR_DETAIL: 'SET_ID_PAYMENT_FOR_DETAIL'
}

export const preferencesActionType = {
    SEND_EDIT_PREFERENCES: 'SEND_EDIT_PREFERENCES',
    REMOVE_PREFERENCES: 'REMOVE_PREFERENCES',
    // FLUSH_TOKEN: 'FLUSH_TOKEN',
    // FLUSH_TOKEN_CONFIG: 'FLUSH_TOKEN_CONFIG',
    GET_VERSION_SITE: 'GET_VERSION_SITE',
    SET_VERSION_SITE: 'SET_VERSION_SITE',
    GET_PRODUCT_LIST: 'GET_PRODUCT_LIST',
    GET_COUNTRY_LIST: 'GET_COUNTRY_LIST',
    SET_TOKEN_FOR_PARTNER: 'SET_TOKEN_FOR_PARTNER',
}

export const workerActionType = {
    ADD_ACTIVE_JOB: 'ADD_ACTIVE_JOB',
    REMOVE_ACTIVE_JOB: 'REMOVE_ACTIVE_JOB',
}

export const promoActionType = {
    SEND_EDIT_PROMO: 'SEND_EDIT_PROMO',
    SEND_NEW_PROMO: 'SEND_NEW_PROMO',
    DELETE_PROMO: 'DELETE_PROMO',
    GET_PROMO_LIST: 'GET_PROMO_LIST'
}

export const roleActionType = {
    ASSIGN_ROLE: 'ASSIGN_ROLE',
    PATCH_ROLE: 'PATCH_ROLE',
    PATCH_UNASSIGN_ROLE: 'PATCH_UNASSIGN_ROLE',
    DELETE_ROLE: 'DELETE_ROLE',
    NEW_ROLE: 'NEW_ROLE',
    UPDATE_ROLE: 'UPDATE_ROLE',
    GET_ROLE: 'GET_ROLE',
    SET_ROLE_LIST: 'SET_ROLE_LIST'
}

export const vpnActionType = {
    ADD_VPN_MACHINE: 'ADD_VPN_MACHINE',
    EDIT_VPN_MACHINE: 'EDIT_VPN_MACHINE',
    DELETE_VPN_MACHINE: 'DELETE_VPN_MACHINE',
    ADD_VPN_PASS: 'ADD_VPN_PASS',
    EDIT_VPN_PASS: 'EDIT_VPN_PASS',
    DELETE_VPN_PASS: 'DELETE_VPN_PASS',
    EDIT_VPN_BOX: 'EDIT_VPN_BOX',
    DELETE_VPN_BOX: 'DELETE_VPN_BOX',
    SETUP_VPN_MACHINE: 'SETUP_VPN_MACHINE',
    GET_VPN_MACHINES_ONLINE: 'GET_VPN_MACHINES_ONLINE',
    SET_VPN_MACHINES_ONLINE: 'SET_VPN_MACHINES_ONLINE',
    SET_NUMBER_VPN_MACHINE: 'SET_NUMBER_VPN_MACHINE',
    SET_NUMBER_VPN_PEER: 'SET_NUMBER_VPN_PEER',
    SET_NUMBER_VPN_PASS: 'SET_NUMBER_VPN_PASS',
    SET_NUMBER_VPN_BOX: 'SET_NUMBER_VPN_BOX',
    SET_ID_VPN_PASS_FOR_DETAIL: 'SET_ID_VPN_PASS_FOR_DETAIL',
    SET_KEY_VPN_PASS_FOR_DETAIL: 'SET_KEY_VPN_PASS_FOR_DETAIL',
    SET_MACHINE_ID_S_FOR_DETAIL: 'SET_MACHINE_ID_S_FOR_DETAIL'
}

export const stockActionType = {
    SET_NUMBER_BOXSTOCK_MOVEMENT: 'SET_NUMBER_BOXSTOCK_MOVEMENT',
    VPN_BOX_STOCK: 'VPN_BOX_STOCK',
    ADD_MOVEMENT_BOXSTOCK: 'ADD_MOVEMENT_BOXSTOCK',
    REMOVE_MOVEMENT_BOXSTOCK: 'REMOVE_MOVEMENT_BOXSTOCK',
    ASSOCIED_TRACKING: 'ASSOCIED_TRACKING',
    GET_VPNBOX_STOCK_INFO: 'GET_VPNBOX_STOCK_INFO',
    START_RENDER: 'START_RENDER',
    REBOOK: 'REBOOK',
    END_RENDER: 'END_RENDER',
}
