import React, { Component, Fragment } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Pagination, Select, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { returnTypeName } from '../../common/actions/returnTypeName';
import { controlRole } from '../../common/actions/controlRole';
import MyTooltip from '../../common/tooltip';
import { isNull } from '../../utility';

export default class formFiltersVpnBoxStock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // quante vpnbox stock vuoi visualizzare
            numberVpnBox: '25',

            // numerpo di pagina selezionato
            currentNumberPage: '1',

            // quale campo ordinare
            fieldOrder: '',

            // tipo di ordinamento
            typeOrder: '',

            idVpnStock: '',
            paymentIdVpnStock: '',
            createdAtStart: null,
            createdAtEnd: null,
            productName: '',
            operationType: 'B',
            emailUserVpnStock: '',
            nameUserVpnStock: '',
            surnameUserVpnStock: '',
            countryUserVpnStock: '',

            changeNumberPage: false
        };

    }

    componentDidMount() {
        this.getVpnBoxStock();
    }

    getVpnBoxStock = () => {
        this.props.setLoading(true);

        if (this.state.fieldOrder !== '' && this.state.typeOrder === '') {
            this.props.warningNotify('Devi inserire il tipo di ordinamento!');
            this.props.setLoading(false);
        } else {
            setTimeout(() => {
                let params = {
                    'elementsByPage': this.state.numberVpnBox,
                    'page': this.state.changeNumberPage ? this.state.currentNumberPage : 1,
                    'sortField': this.state.fieldOrder || '',
                    'sortType': this.state.typeOrder || '',
                    'filters[0][Id]': this.state.idVpnStock || '',
                    'filters[1][PaymentId]': this.state.paymentIdVpnStock || '',
                    'filters[2][CreatedAtStart]': this.state.createdAtStart === null ? '' : this.setHourStart(this.state.createdAtStart.$d),
                    'filters[3][CreatedAtEnd]': this.state.createdAtEnd === null ? '' : this.setHourEnd(this.state.createdAtEnd.$d),
                    'filters[4][Type]': this.state.operationType || '',
                    'filters[5][Email]': this.state.emailUserVpnStock || '',
                    'filters[6][Name]': this.state.nameUserVpnStock || '',
                    'filters[7][Surname]': this.state.surnameUserVpnStock || '',
                    'filters[8][Country]': this.state.countryUserVpnStock || '',
                    'filters[9][ProductName]': this.state.productName || '',
                }
                this.props.getVpnBoxStock(params);

                if (!this.state.changeNumberPage) {
                    this.setState({
                        currentNumberPage: 1
                    });
                }

                if (this.state.changeNumberPage) {
                    this.setState({
                        changeNumberPage: false,
                    });
                }

                this.props.setLoading(false);
            }, 300);
        }
    }

    /**
     * Cambia il valore di quello stato.
     * 
     * @param {*} event 
     */
    onChange = (event, page) => {
        this.setState({ [event.target.name]: event.target.value });

        if (event.target.name === '' || event.target.name === undefined) {
            this.setState({
                currentNumberPage: page,
                changeNumberPage: true
            });
            setTimeout(() => {
                this.getVpnBoxStock();
            }, 200);
        }

        if (
            event.target.name === 'typeOrder' ||
            event.target.name === 'operationType' ||
            event.target.name === 'countryUserVpnStock'
        ) {
            setTimeout(() => {
                this.getVpnBoxStock();
            }, 200);
        }
    }

    /**
     * Se decido di cambiare il numero di vpnbox stock.
     * 
     * @param {*} number 
     */
    setNumberVpnStockForSearch = (number) => {
        this.setState({ numberVpnBox: number });
        setTimeout(() => {
            this.getVpnBoxStock();
        }, 200);
    }

    setHourStart = (newHour) => {
        newHour.setHours(1);
        newHour.setMinutes(0);
        newHour.setSeconds(0);
        newHour.setMilliseconds(0);
        return newHour;
    }

    setHourEnd = (newHour) => {
        newHour.setHours(0);
        newHour.setMinutes(59);
        newHour.setSeconds(59);
        newHour.setMilliseconds(999);
        return newHour;
    }

    // change per le date
    setDateCreatedStartAt = (newDate) => { this.setState({ createdAtStart: newDate }); }
    setDateCreatedEndAt = (newDate) => { this.setState({ createdAtEnd: newDate }); }

    // Resetta tutti i filtri
    resetFilter = () => {
        this.setState({
            numberVpnBox: '25',
            currentNumberPage: '1',
            fieldOrder: '',
            typeOrder: '',
            idVpnStock: '',
            paymentIdVpnStock: '',
            createdAtStart: null,
            createdAtEnd: null,
            operationType: 'B',
            emailUserVpnStock: '',
            nameUserVpnStock: '',
            surnameUserVpnStock: '',
            countryUserVpnStock: '',
            productName: '',
            changeNumberPage: false
        });

        setTimeout(() => {
            this.getVpnBoxStock();
        }, 300);
    }

    /**
     * Se viene premuto il tasto enter (Invio) avvia una ricerca
     */
    clickEnter = (event) => {
        if (event.keyCode === 13) {
            this.getVpnBoxStock();
        }
    }

    render() {
        const { stock, preferences, role } = this.props;
        return (
            <Fragment>
                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="idVpnStock"
                            variant='standard'
                            value={this.state.idVpnStock}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="ID Stock"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <FormControl className='customSelect'>
                            <InputLabel>Tipo di prodotto</InputLabel>
                            <Select
                                labelId="productName"
                                name="productName"
                                variant='standard'
                                value={this.state.productName}
                                onChange={this.onChange}
                            >
                                {
                                    preferences.listProductBoxHw.map((el, key) => {
                                        return (
                                            <MenuItem key={key} value={el.name}>{returnTypeName(el.type)} - {returnTypeName(el.name)}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <FormControl className='customSelect'>
                            <InputLabel>Country</InputLabel>
                            <Select
                                labelId="countryUserVpnStock"
                                name="countryUserVpnStock"
                                variant='standard'
                                value={this.state.countryUserVpnStock}
                                onChange={this.onChange}
                            >
                                {
                                    preferences.countryPurchasableList.map((el, key) => {
                                        return (
                                            <MenuItem key={key} value={el.code}>{el.description}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        {
                            controlRole(role.vpnBoxStock, "api/VpnBoxStocks", "POST") &&
                            <MyTooltip
                                title="Box caricate"
                                position="top"
                                content={
                                    <button
                                        className={this.state.operationType === 'L' ? "btnAround selected" : "btnAround"}
                                        onClick={() => this.onChange({ target: { name: 'operationType', value: 'L' } })}
                                    > <i className="fas fa-download icon" /> </button>
                                }
                            />
                        }
                        <MyTooltip
                            title="Box da spedire"
                            position="top"
                            content={
                                <button
                                    className={this.state.operationType === 'B' ? "btnAround selected" : "btnAround"}
                                    onClick={() => this.onChange({ target: { name: 'operationType', value: 'B' } })}
                                > <i className="fas fa-shipping-fast icon" /> </button>
                            }
                        />
                        <MyTooltip
                            title="Box vendute"
                            position="top"
                            content={
                                <button
                                    className={this.state.operationType === 'U' ? "btnAround selected" : "btnAround"}
                                    onClick={() => this.onChange({ target: { name: 'operationType', value: 'U' } })}
                                > <i className="fas fa-upload icon" /> </button>
                            }
                        />
                    </div>
                    <div className="col-12 text-center">
                        <MyTooltip
                            title="Assistenza"
                            position="top"
                            content={
                                <button
                                    className={this.state.operationType === 'D' ? "btnAround selected" : "btnAround"}
                                    onClick={() => this.onChange({ target: { name: 'operationType', value: 'D' } })}
                                > <i className="fas fa-wrench icon" /> </button>
                            }
                        />
                        <MyTooltip
                            title="Resi"
                            position="top"
                            content={
                                <button
                                    className={this.state.operationType === 'R' ? "btnAround selected" : "btnAround"}
                                    onClick={() => this.onChange({ target: { name: 'operationType', value: 'R' } })}
                                > <i className="fas fa-truck-loading icon" /> </button>
                            }
                        />

                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="emailUserVpnStock"
                            variant='standard'
                            value={this.state.emailUserVpnStock}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="Email"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="nameUserVpnStock"
                            variant='standard'
                            value={this.state.nameUserVpnStock}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="Nome"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="surnameUserVpnStock"
                            variant='standard'
                            value={this.state.surnameUserVpnStock}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="Cognome"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button
                            className={this.state.numberVpnBox === '10' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberVpnStockForSearch('10')}
                        > 10 </button>
                        <button
                            className={this.state.numberVpnBox === '25' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberVpnStockForSearch('25')}
                        > 25 </button>
                        <button
                            className={this.state.numberVpnBox === '50' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberVpnStockForSearch('50')}
                        > 50 </button>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <Pagination
                            boundaryCount={2}
                            count={parseInt(stock.totalPages) || 0}
                            page={parseInt(this.state.currentNumberPage)}
                            onChange={this.onChange}
                            color="primary"
                            showFirstButton
                            showLastButton
                            size="small"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button className="btn btn-primary" style={{ 'margin': '10px' }} onClick={this.getVpnBoxStock}>
                            Cerca
                        </button>
                        <button className="btn btn-secondary" style={{ 'margin': '10px' }} onClick={this.resetFilter}>
                            Reset
                        </button>
                    </div>
                </div>

                <Accordion className="myAccordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>Altri filtri</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div">
                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Come vuoi ordinare?</InputLabel>
                                        <Select
                                            labelId="fieldOrderLabel"
                                            name="fieldOrder"
                                            variant='standard'
                                            value={this.state.fieldOrder}
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value={'CreatedAt'}>Data di creazione</MenuItem>
                                            <MenuItem value={'Type'}>Tipo di operazione</MenuItem>
                                            <MenuItem value={'Qty'}>Quantità</MenuItem>
                                            <MenuItem value={'ProductName'}>Tipo di Prodotto</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-12 itemForSearch">
                                    <FormControl disabled={this.state.fieldOrder === ''} className='customSelect'>
                                        <InputLabel>Tipo di Ordinamento</InputLabel>
                                        <Select
                                            labelId="typeOrderLabel"
                                            name="typeOrder"
                                            variant='standard'
                                            value={this.state.typeOrder}
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value={'asc'}>Crescente [A-Z]</MenuItem>
                                            <MenuItem value={'desc'}>Decrescente [Z-A]</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            variant="inline"
                                            format="DD/MM/YYYY"
                                            id="createdAtStart"
                                            label="Creata dal"
                                            value={this.state.createdAtStart}
                                            onChange={(newValue) => this.setDateCreatedStartAt(isNull(newValue) ? null : dayjs(newValue.$d))}
                                        />
                                    </div>

                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            variant="inline"
                                            id="createdAtEnd"
                                            label="Fino a"
                                            value={this.state.createdAtEnd}
                                            onChange={(newValue) => this.setDateCreatedEndAt(isNull(newValue) ? null : dayjs(newValue.$d))}
                                            format="DD/MM/YYYY"
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>

                        </Typography>
                    </AccordionDetails>
                </Accordion>

            </Fragment>
        )
    }
}