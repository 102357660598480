import React, { Component } from 'react';
import { FormControl, TextField } from '@mui/material';
import moment from 'moment';
import { isNull } from '../../utility';

export default class expanableComponent extends Component {
    /**
     * Data una data in formato iso, restituisce una data correttamente stampata
     * nel seguente formato: September 4 1986 - 08:30:00 PM
     */
    formatData = (data) => {
        if (data === null || data === undefined || data === '') {
            return '';
        } else {
            return moment(data).format('LL') + ' - ' + moment(data).format('LTS');
        }
    }

    render() {
        const { data } = this.props;
        return (
            <div className="tabExpanablePlayer">
                <FormControl>
                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={data.credentials.host || ' '}
                                label="Host"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={data.credentials.userId || ' '}
                                label="Root User"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={data.credentials.password || ' '}
                                label="Root Password"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <TextField
                                value={data.key || ' '}
                                label="ID Macchina VPN"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={data.mantainer || ' '}
                                label="Mantainer"
                                variant='standard'
                            />
                        </div>

                        <div className="col-4">
                            <TextField
                                value={data.serverVersion || ' '}
                                label="Server Version"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <TextField
                                value={!isNull(data.publicKey) ? data.publicKey : ""}
                                label="Public Key"
                                variant='standard'
                            />
                        </div>

                        <div className="col-6">
                            <TextField
                                value={!isNull(data.privateKey) ? data.privateKey : ""}
                                label="Private Key"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <TextField
                                value={!isNull(data.publicHost) ? data.publicHost : ""}
                                label="Public Host"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <TextField
                                value={this.formatData(data.createdAt) || ' '}
                                label="Creata il"
                                variant='standard'
                            />
                        </div>

                        <div className="col-6">
                            <TextField
                                value={this.formatData(data.updatedAt) || ' '}
                                label="Modificata il"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <TextField
                                value={data.os || ' '}
                                label="Sistema Operativo"
                                variant='standard'
                            />
                        </div>

                        <div className="col">
                            <TextField
                                value={data.networkInterfaceName || ' '}
                                label="Nome interfaccia di rete"
                                variant='standard'
                            />
                        </div>

                        <div className="col">
                            <TextField
                                value={data.cpuDescription || ' '}
                                label="Descrizione CPU"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <TextField
                                value={!isNull(data.tx) ? data.tx : ""}
                                label="TX"
                                variant='standard'
                            />
                        </div>

                        <div className="col">
                            <TextField
                                value={!isNull(data.rx) ? data.rx : ""}
                                label="RX"
                                variant='standard'
                            />
                        </div>

                        <div className="col">
                            <TextField
                                value={!isNull(data.memory) ? data.memory : ""}
                                label="RAM"
                                variant='standard'
                            />
                        </div>

                        <div className="col">
                            <TextField
                                value={data.cpu ? data.cpu : ""}
                                label="CPU"
                                variant='standard'
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <TextField
                                value={data.formula || ' '}
                                label="Formula per calcolo Score"
                                variant='standard'
                            />
                        </div>
                    </div>
                </FormControl>
            </div>
        )
    }
}