import React, { Component, Fragment } from 'react';
import { preferencesActions } from '../../store/actions';
import { connect } from 'react-redux'
import moment from 'moment';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Pagination, Select, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { isNull, withLocation } from '../../utility';

class formFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // elementi totali presenti nel DB (in questo caso quanti utenti ci sono)
            totalElements: 0,
            totalPages: '',

            // numeri della pagina che si possono visualizzare (si popola in base alla risposta del server)
            numbersPage: [],

            // quante promo visualizzare
            numberPromo: '25',

            // numerpo di pagina selezionato
            currentNumberPage: '1',

            // quale campo ordinare
            fieldOrder: 'startAt',

            // tipo di ordinamento
            typeOrder: 'desc',

            changeNumberPage: false,

            // inizializzazione dell'array promo filters
            promoFiltersIsCoupon: '',
            promoFiltersStartAtStart: moment().utc().startOf('month').format('YYYY-MM-DD'),
            promoFiltersEndAtEnd: null,
            promoFiltersStartAtEnd: moment().utc().endOf('month').format('YYYY-MM-DD'),
            promoFiltersEndAtStart: null,
            promoFiltersWebUser: '',
            promoFiltersDescription: '',
            promoFiltersProduct: '',
            promoFiltersDiscountMax: '',
            promoFiltersDiscountMin: '',
            promoFiltersUnlimitedUses: '',
            promoFiltersId: '',
            promoFiltersGroup: '',
            codeFilter: '',

            assignedCoupon: '',
        }

        this.setChange = this.setChange.bind(this);
        this.setStartAtStartChange = this.setStartAtStartChange.bind(this);
        this.setStartAtEndChange = this.setStartAtEndChange.bind(this);
        this.setEndAtStartChange = this.setEndAtStartChange.bind(this);
        this.setEndAtEndChange = this.setEndAtEndChange.bind(this);
    }

    setChange = (event, page) => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === '' || event.target.name === undefined) {
            this.setState({
                currentNumberPage: page,
                changeNumberPage: true
            });
            setTimeout(() => {
                this.getPromoList(false);
            }, 200);
        }
        if (event.target.name === 'numberPromo' || event.target.name === 'typeOrder' || event.target.name === 'assignedCoupon') {
            setTimeout(() => {
                this.getPromoList(false);
            }, 200);
        }
    }

    /**
     * Chiama il BE e ritorna la lista degli utenti in base ai parametri di ricerca inseriti
     */
    getPromoList = (isReset) => {
        this.props.setLoading(true);

        // variabili per capire in che sezione mi trovo (Promo, Coupon Geberati, Coupon Venduti, Promo Scadute, Coupon Scaduti)
        const createdCouponSection = this.props.location.pathname === '/promo/createdCoupon';
        const buyCouponSection = this.props.location.pathname === '/promo/buyCoupon';
        const expiredPromoSection = this.props.location.pathname === '/promo/expiredPromo';
        const expiredCouponSection = this.props.location.pathname === '/promo/expiredCoupon';

        if (this.state.fieldOrder !== '' && this.state.typeOrder === '') {
            this.props.warningNotify('Devi inserire il tipo di ordinamento!');
            this.props.setLoading(false);
        } else {
            setTimeout(() => {
                let params = {
                    'elementsByPage': this.state.numberPromo,
                    'page': this.state.changeNumberPage ? this.state.currentNumberPage : 1,
                    'sortField': this.state.fieldOrder,
                    'sortType': this.state.typeOrder,
                    'promoFilters[0][IsCoupon]': (createdCouponSection || buyCouponSection || expiredCouponSection),
                    'promoFilters[1][StartAtStart]': this.state.promoFiltersStartAtStart === null ? '' : this.state.promoFiltersStartAtStart,
                    'promoFilters[2][EndAtEnd]': this.state.promoFiltersEndAtEnd === null ? '' : this.setHourEnd(this.state.promoFiltersEndAtEnd.$d),
                    'promoFilters[3][StartAtEnd]': this.state.promoFiltersStartAtEnd === null ? '' : this.state.promoFiltersStartAtEnd,
                    'promoFilters[4][EndAtStart]': this.state.promoFiltersEndAtStart === null ? '' : this.setHourStart(this.state.promoFiltersEndAtStart.$d),
                    'promoFilters[5][WebUser]': this.state.promoFiltersWebUser,
                    'promoFilters[6][Description]': this.state.promoFiltersDescription,
                    'promoFilters[7][Product]': this.state.promoFiltersProduct,
                    'promoFilters[8][DiscountMax]': this.state.promoFiltersDiscountMax,
                    'promoFilters[9][DiscountMin]': this.state.promoFiltersDiscountMin,
                    'promoFilters[10][UnlimitedUses]': this.state.promoFiltersUnlimitedUses,
                    'promoFilters[11][Id]': this.state.promoFiltersId,
                    'promoFilters[12][Code]': this.state.codeFilter,
                    'promoFilters[13][Group]': this.state.promoFiltersGroup,
                    'promoFilters[14][AssignedCoupon]': this.state.assignedCoupon === 'off' ? '' : this.state.assignedCoupon,
                    'promoFilters[15][IsPaid]': (expiredPromoSection || expiredCouponSection) ? '' : buyCouponSection,
                    'promoFilters[16][IsRotten]': (expiredPromoSection || expiredCouponSection)
                };

                this.props.getPromoList(params, isReset);

                if (!this.state.changeNumberPage) {
                    this.setState({
                        currentNumberPage: 1
                    });
                }

                if (this.state.changeNumberPage) {
                    this.setState({
                        changeNumberPage: false,
                    });
                }

                this.props.setLoading(false);
            }, 300);
        }
    }

    /**
     * In base alla risposta del server, 
     * setta il numero delle pagine disponibili per la paginazione
     */
    setNumbersPage = (numbersPage) => {
        let temp = [];
        for (let i = 1; i <= parseInt(numbersPage); i++) {
            temp.push(i);
        }
        this.setState({ numbersPage: temp });
    }

    setHourStart = (newHour) => {
        newHour.setHours(1);
        newHour.setMinutes(0);
        newHour.setSeconds(0);
        newHour.setMilliseconds(0);
        return newHour;
    }

    setHourEnd = (newHour) => {
        newHour.setHours(0);
        newHour.setMinutes(59);
        newHour.setSeconds(59);
        newHour.setMilliseconds(999);
        return newHour;
    }

    setStartAtStartChange = (newDate) => {
        this.setState({ promoFiltersStartAtStart: newDate });
    }

    setStartAtEndChange = (newDate) => {
        this.setState({ promoFiltersStartAtEnd: newDate });
    }

    setEndAtStartChange = (newDate) => {
        this.setState({ promoFiltersEndAtStart: newDate });
    }

    setEndAtEndChange = (newDate) => {
        this.setState({ promoFiltersEndAtEnd: newDate });
    }

    /**
     * Se viene premuto il tasto enter (Invio) avvia una ricerca
     */
    clickEnter = (event) => {
        if (event.keyCode === 13) {
            this.getPromoList(false);
        }
    }

    // Resetta tutti i filtri
    resetFilter = () => {
        this.setState({
            numberPromo: '25',
            numbersPage: [],
            currentNumberPage: '1',
            fieldOrder: 'startAt',
            typeOrder: 'desc',
            promoFiltersIsCoupon: '',
            promoFiltersStartAtStart: moment().utc().startOf('month').format('YYYY-MM-DD'),
            promoFiltersEndAtEnd: null,
            promoFiltersStartAtEnd: moment().utc().endOf('month').format('YYYY-MM-DD'),
            promoFiltersEndAtStart: null,
            promoFiltersWebUser: '',
            promoFiltersDescription: '',
            promoFiltersProduct: '',
            promoFiltersDiscountMax: '',
            promoFiltersDiscountMin: '',
            promoFiltersUnlimitedUses: '',
            promoFiltersGroup: '',
            promoFiltersId: '',
            codeFilter: '',
            totalElements: 0,
            totalPages: '',
            assignedCoupon: ''
        });

        setTimeout(() => {
            this.getPromoList(true);
        }, 300);
    }

    /**
     * Se decido di cambiare il numero delle promo.
     * 
     * @param {*} number 
     */
    setNumberPromoForSearch = (number) => {
        this.setState({ numberPromo: number });
        setTimeout(() => {
            this.getPromoList(false);
        }, 200);
    }

    render() {
        const { preferences, promo } = this.props;

        return (
            <Fragment>
                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="codeFilter"
                            variant='standard'
                            value={this.state.codeFilter}
                            onChange={this.setChange}
                            onKeyDown={this.clickEnter}
                            label="Codice"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="promoFiltersDescription"
                            variant='standard'
                            value={this.state.promoFiltersDescription}
                            onChange={this.setChange}
                            onKeyDown={this.clickEnter}
                            label="Descrizione"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="promoFiltersId"
                            variant='standard'
                            value={this.state.promoFiltersId}
                            onChange={this.setChange}
                            onKeyDown={this.clickEnter}
                            label="ID promo"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <FormControl className='customSelect'>
                            <InputLabel>Coupon Assegnati</InputLabel>
                            <Select
                                labelId="typeAssignedCoupon"
                                name="assignedCoupon"
                                variant='standard'
                                value={this.state.assignedCoupon}
                                onChange={this.setChange}
                            >
                                <MenuItem value={'off'}>OFF</MenuItem>
                                <MenuItem value={false}>Coupon Non Assegnati</MenuItem>
                                <MenuItem value={true}>Coupon Assegnati</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="row">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <div className="col-12 itemForSearch">
                            <DatePicker
                                id="startAtStart"
                                variant="inline"
                                label="Data inizio da"
                                value={dayjs(this.state.promoFiltersStartAtStart)}
                                onChange={(newValue) => this.setStartAtStartChange(isNull(newValue) ? null : dayjs(newValue.$d))}
                                format="DD/MM/YYYY"
                            />
                        </div>

                        <div className="col-12 itemForSearch">
                            <DatePicker
                                id="startAtEnd"
                                variant="inline"
                                label="Data inizio fino a"
                                value={dayjs(this.state.promoFiltersStartAtEnd)}
                                onChange={(newValue) => this.setStartAtEndChange(isNull(newValue) ? null : dayjs(newValue.$d))}
                                format="DD/MM/YYYY"
                            />
                        </div>
                    </LocalizationProvider>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button
                            className={this.state.numberPromo === '10' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberPromoForSearch('10')}
                        > 10 </button>
                        <button
                            className={this.state.numberPromo === '25' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberPromoForSearch('25')}
                        > 25 </button>
                        <button
                            className={this.state.numberPromo === '50' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberPromoForSearch('50')}
                        > 50 </button>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <Pagination
                            boundaryCount={2}
                            count={parseInt(promo.totalPages) || 0}
                            page={parseInt(this.state.currentNumberPage)}
                            onChange={this.setChange}
                            color="primary"
                            showFirstButton
                            showLastButton
                            size="small"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button className="btn btn-primary" style={{ 'margin': '10px' }} onClick={() => this.getPromoList(false)}>
                            Cerca
                        </button>
                        <button className="btn btn-secondary" style={{ 'margin': '10px' }} onClick={this.resetFilter}>
                            Reset
                        </button>
                    </div>
                </div>

                <Accordion className="myAccordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>Altri filtri</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div">
                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Come vuoi ordinare?</InputLabel>
                                        <Select
                                            labelId="fieldOrderLabel"
                                            name="fieldOrder"
                                            variant='standard'
                                            value={this.state.fieldOrder}
                                            onChange={this.setChange}
                                        >
                                            <MenuItem value={'webUser'}>Utente Associato</MenuItem>
                                            <MenuItem value={'_id'}>ID Promo</MenuItem>
                                            <MenuItem value={'startAt'}>Data Inizio</MenuItem>
                                            <MenuItem value={'endAt'}>Data Fine</MenuItem>
                                            <MenuItem value={'discount'}>Percentuale Sconto</MenuItem>
                                            <MenuItem value={'maxItems'}>Numero utilizzi</MenuItem>
                                            <MenuItem value={'description'}>Descrizione</MenuItem>
                                            <MenuItem value={'code'}>Codice</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-12 itemForSearch">
                                    <FormControl disabled={this.state.fieldOrder === ''} className='customSelect'>
                                        <InputLabel>Tipo di Ordinamento</InputLabel>
                                        <Select
                                            labelId="typeOrderLabel"
                                            name="typeOrder"
                                            variant='standard'
                                            value={this.state.typeOrder}
                                            onChange={this.setChange}
                                        >
                                            <MenuItem value={'asc'}>Crescente [A-Z]</MenuItem>
                                            <MenuItem value={'desc'}>Decrescente [Z-A]</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            id="endAtStart"
                                            variant="inline"
                                            label="Data fine da"
                                            value={this.state.promoFiltersEndAtStart}
                                            onChange={(newValue) => this.setEndAtStartChange(isNull(newValue) ? null : dayjs(newValue.$d))}
                                            format="DD/MM/YYYY"
                                        />
                                    </div>

                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            id="endAtEnd"
                                            variant="inline"
                                            label="Data fine fino a"
                                            value={this.state.promoFiltersEndAtEnd}
                                            onChange={(newValue) => this.setEndAtEndChange(isNull(newValue) ? null : dayjs(newValue.$d))}
                                            format="DD/MM/YYYY"
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="promoFiltersWebUser"
                                        variant='standard'
                                        value={this.state.promoFiltersWebUser}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="Email Utente"
                                    />
                                </div>

                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="promoFiltersDiscountMin"
                                        variant='standard'
                                        value={this.state.promoFiltersDiscountMin}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="Sconto dal"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="promoFiltersDiscountMax"
                                        variant='standard'
                                        value={this.state.promoFiltersDiscountMax}
                                        onChange={this.setChange}
                                        label="Sconto fino al"
                                        onKeyDown={this.clickEnter}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="promoFiltersGroup"
                                        variant='standard'
                                        value={this.state.promoFiltersGroup}
                                        onChange={this.setChange}
                                        onKeyDown={this.clickEnter}
                                        label="Gruppo"
                                    />
                                </div>

                                <div className="col-12 itemForSearch">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Prodotto</InputLabel>
                                        <Select
                                            labelId="promoFiltersProduct"
                                            name="promoFiltersProduct"
                                            variant='standard'
                                            value={this.state.promoFiltersProduct}
                                            onChange={this.setChange}
                                            onKeyDown={this.clickEnter}
                                        >
                                            {
                                                preferences.allProducts.map(el => {
                                                    return (
                                                        <MenuItem key={el} value={el.name}>{el.name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        ...state,
        preferences: state.preferences
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProductList: (productType) => {
            dispatch(preferencesActions.getProductList(productType))
        },
    };
};

export default withLocation(connect(mapStateToProps, mapDispatchToProps)(formFilters));