import { connect } from 'react-redux'
import { vpnActions, notifyActions } from '../store/actions';
import pageVpn from './pages/machine';

const mapStateToProps = state => {
    return {
        ...state,
        vpn: state.vpn,
        role: state.role,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addVpnMachine: (data) => {
            dispatch(vpnActions.addVpnMachine(data))
        },
        editVpnMachine: (data) => {
            dispatch(vpnActions.editVpnMachine(data))
        },
        removeVpnMachine: (id) => {
            dispatch(vpnActions.removeVpnMachine(id))
        },
        setupVpnMachine: (id) => {
            dispatch(vpnActions.setupVpnMachine(id))
        },
        setNumberVpnMachine: (data) => {
            dispatch(vpnActions.setNumberVpnMachine(data))
        },
        setMachineIdForDetail: (data) => {
            dispatch(vpnActions.setMachineIdForDetail(data))
        },
        successNotify: (message) => {
            dispatch(notifyActions.successAction(message))
        },
        warningNotify: (message) => {
            dispatch(notifyActions.warningAction(message))
        },
        errorNotify: (message) => {
            dispatch(notifyActions.errorAction(message))
        },
        infoNotify: (message) => {
            dispatch(notifyActions.infoAction(message))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(pageVpn);