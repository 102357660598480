import React, { Component, Fragment } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Pagination, Select, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { isNull } from '../../utility';

export default class FormFiltersLicence extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // quale campo ordinare
            fieldOrder: '',

            // quanti player vuoi visualizzare
            numberLicence: '25',

            // numerpo di pagina selezionato
            currentNumberPage: '1',
            changeNumberPage: false,

            // tipo di ordinamento
            typeOrder: '',

            // inizializzazione dell'array filters
            filterPlatform: '',
            filterKey: '',
            filterId: '',
            filterCreatedAtStart: null,
            filterCreatedAtEnd: null,
            filterPaymentId: '',
            filterPlayerId: '',
            filterUser: ''
        }
    }

    /**
     * Chiama il BE e ritorna la lista degli utenti in base ai parametri di ricerca inseriti
     */
    getLicenceList = () => {
        let params = {
            'elementsByPage': this.state.numberLicence,
            'page': this.state.changeNumberPage ? this.state.currentNumberPage : 1,
            'sortField': this.state.fieldOrder,
            'sortType': this.state.typeOrder,
            'filters[0][Platform]': this.state.filterPlatform,
            'filters[1][Key]': this.state.filterKey,
            'filters[2][Id]': this.state.filterId,
            'filters[3][CreatedAtStart]': this.state.filterCreatedAtStart === null ? '' : this.setHourStart(this.state.filterCreatedAtStart),
            'filters[4][CreatedAtEnd]': this.state.filterCreatedAtEnd === null ? '' : this.setHourEnd(this.state.filterCreatedAtEnd),
            'filters[5][PaymentId]': this.state.filterPaymentId,
            'filters[6][PlayerId]': this.state.filterPlayerId,
            'filters[7][User]': this.state.filterUser
        }

        setTimeout(() => {
            this.props.getLicenceList(params);
        }, 500);

        if (!this.state.changeNumberPage) {
            this.setState({
                currentNumberPage: 1
            });
        }

        if (this.state.changeNumberPage) {
            this.setState({
                changeNumberPage: false,
            });
        }
    }

    onChange = (event, page) => {
        this.setState({ [event.target.name]: event.target.value });

        if (event.target.name === '' || event.target.name === undefined) {
            this.setState({
                currentNumberPage: page,
                changeNumberPage: true
            });

            setTimeout(() => {
                this.getLicenceList(true);
            }, 500);
        }

        if (event.target.name === 'numberLicence' || event.target.name === 'typeOrder') {
            setTimeout(() => {
                this.getLicenceList();
            }, 200);
        }
    }

    setHourStart = (newHour) => {
        newHour.setHours(1);
        newHour.setMinutes(0);
        newHour.setSeconds(0);
        newHour.setMilliseconds(0);
        return newHour;
    }

    setHourEnd = (newHour) => {
        newHour.setHours(0);
        newHour.setMinutes(59);
        newHour.setSeconds(59);
        newHour.setMilliseconds(999);
        return newHour;
    }

    setCreatedAtStart = (newDate) => {
        this.setState({ filterCreatedAtStart: newDate });
    }

    setCreatedAtEnd = (newDate) => {
        this.setState({ filterCreatedAtEnd: newDate });
    }

    /**
     * Se decido di cambiare il numero dei player.
     * 
     * @param {*} number 
     */
    setNumberLicenceForSearch = (number) => {
        this.setState({ numberLicence: number });
        setTimeout(() => {
            this.getLicenceList();
        }, 200);
    }

    /**
     * Se viene premuto il tasto enter (Invio) avvia una ricerca
     */
    clickEnter = (event) => {
        if (event.keyCode === 13) {
            this.getLicenceList();
        }
    }


    // Resetta tutti i filtri
    resetFilter = () => {
        this.setState({
            numberLicence: '25',
            currentNumberPage: '1',

            fieldOrder: '',
            typeOrder: '',

            filterPlatform: '',
            filterKey: '',
            filterId: '',
            filterCreatedAtStart: null,
            filterCreatedAtEnd: null,
            filterPaymentId: '',
            filterPlayerId: '',
            filterUser: ''
        });

        setTimeout(() => {
            this.getLicenceList(false);
        }, 300);
    }

    render() {
        return (
            <Fragment>
                <div className="row itemForSearch">
                    <div className="col-12 itemForSearch">
                        <TextField
                            name="filterPaymentId"
                            variant='standard'
                            value={this.state.filterPaymentId}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="Payment ID"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 itemForSearch">
                        <TextField
                            name="filterUser"
                            variant='standard'
                            value={this.state.filterUser}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="Email Utente"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="filterKey"
                            variant='standard'
                            value={this.state.filterKey}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="Codice Attivazione"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button
                            className={this.props.player.elementForPageLicences === '10' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberLicenceForSearch('10')}
                        > 10 </button>
                        <button
                            className={this.props.player.elementForPageLicences === '25' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberLicenceForSearch('25')}
                        > 25 </button>
                        <button
                            className={this.props.player.elementForPageLicences === '50' ? "btnAround selected" : "btnAround"}
                            onClick={() => this.setNumberLicenceForSearch('50')}
                        > 50 </button>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <Pagination
                            boundaryCount={2}
                            count={parseInt(this.props.player.totalPagesLicences) || 0}
                            page={parseInt(this.state.currentNumberPage)}
                            onChange={this.onChange}
                            color="primary"
                            showFirstButton
                            showLastButton
                            size="small"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button className="btn btn-primary" style={{ 'margin': '10px' }} onClick={() => this.getLicenceList(false)}>
                            Cerca
                        </button>
                        <button className="btn btn-secondary" style={{ 'margin': '10px' }} onClick={this.resetFilter}>
                            Reset
                        </button>
                    </div>
                </div>

                <Accordion className="myAccordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>Altri filtri</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div">
                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl className='customSelect'>
                                        <InputLabel>Come vuoi ordinare?</InputLabel>
                                        <Select
                                            labelId="fieldOrderPlayersLabel"
                                            name="fieldOrder"
                                            variant='standard'
                                            value={this.state.fieldOrder}
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value={'Key'}>Codice Attivazione</MenuItem>
                                            <MenuItem value={'Platform'}>Platform</MenuItem>
                                            <MenuItem value={'User'}>Utente</MenuItem>
                                            <MenuItem value={'Id'}>ID Device</MenuItem>
                                            <MenuItem value={'CreatedAt'}>CreatedAt</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-12 itemForSearch">
                                    <FormControl disabled={this.state.fieldOrder === ''} className='customSelect'>
                                        <InputLabel>Tipo di Ordinamento</InputLabel>
                                        <Select
                                            labelId="typeOrderLabel"
                                            name="typeOrder"
                                            variant='standard'
                                            value={this.state.typeOrder}
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value={'asc'}>Crescente [A-Z]</MenuItem>
                                            <MenuItem value={'desc'}>Decrescente [Z-A]</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            id="filterCreatedAtStart"
                                            variant="inline"
                                            label="Creazione da"
                                            name="filterCreatedAtStart"
                                            value={this.state.filterCreatedAtStart}
                                            onChange={(newValue) => this.setCreatedAtStart(isNull(newValue) ? null : dayjs(newValue.$d))}
                                            format="DD/MM/YYYY"
                                        />
                                    </div>

                                    <div className="col-12 itemForSearch">
                                        <DatePicker
                                            id="filterCreatedAtEnd"
                                            variant="inline"
                                            label="Fino a"
                                            name="filterCreatedAtEnd"
                                            value={this.state.filterCreatedAtEnd}
                                            onChange={(newValue) => this.setCreatedAtEnd(isNull(newValue) ? null : dayjs(newValue.$d))}
                                            format="DD/MM/YYYY"
                                        />
                                    </div>
                                </LocalizationProvider>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="filterPlatform"
                                        variant='standard'
                                        value={this.state.filterPlatform}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Piattaforma"
                                    />
                                </div>

                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="filterPlayerId"
                                        variant='standard'
                                        value={this.state.filterPlayerId}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Device ID Player"
                                    />
                                </div>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Fragment>
        )
    }
}